import React, { useEffect } from 'react';
import './css/allGames.css'
import Layout from './layout/Layout'
import { useNavigate, useParams } from 'react-router-dom';
import gameConfig from '../utils/gamesConfig';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/carousel.css';
import { footerImages } from '../utils/constant';
import { checkStatus } from '../services/user-api';

const AllGames = () => {
    const navigate = useNavigate();
    const { categoryName } = useParams();

    let filterGames = categoryName ? gameConfig.find((item) => item.category_name === categoryName) : gameConfig.map((item) => item);

    let games = categoryName
        ? filterGames.games
        : [
            ...filterGames[0].games,
            ...filterGames[1].games,
            ...filterGames[2].games,
            ...filterGames[3].games,
            ...filterGames[4].games,
            ...filterGames[5].games,
            ...filterGames[6].games,
        ];

    const topTenSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 768, // Adjust the breakpoint as needed
                settings: {
                    slidesToShow: 2, // Show 2 slides at a time on mobile devices
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1196, // Adjust the breakpoint as needed
                settings: {
                    slidesToShow: 3, // Show 2 slides at a time on mobile devices
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1400, // Adjust the breakpoint as needed
                settings: {
                    slidesToShow: 4, // Show 2 slides at a time on mobile devices
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const handlePlayGame = () => {
        const token = localStorage.getItem('token');
            if (!token) {
                navigate("/login");
            } else {
            checkStatus().then((res) => {
                navigate('/allGames')
            }).catch((err) => {
                console.log(err);
                if(err.status===401){
                localStorage.clear();
                window.location.href ="/login";
            }
            })
        } 
    }   

    // Function to handle the click event
    const handleDownload = (gameUrl) => {
        const gameName = gameUrl.split('/');
        const link = document.createElement('a');
        link.href = gameUrl;
        link.download = gameName[gameName.length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const token = localStorage.getItem('token');
        if (!token) {
            navigate("/login");
        } else {
        checkStatus().then((res) => { // success
        }).catch((err) => {
            console.log(err);
            if(err.status===401){
            localStorage.clear();
            window.location.href ="/login";
        }
        })
    } 
    }, []);

    return (
        <Layout>
            <div className='games_page'>
                <section className='intro_part'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-5'>
                                <div className='intro_text_container'>
                                    <h2>UV <span>GAMING</span></h2>
                                    <p>FIND NEW GAMES BASED ON NEW TECHNOLOGY WHICH ARE FULL WITH FUN, ENTERTAINMENT AND KNOWLEDGE.</p>
                                    <button onClick={handlePlayGame}>PLAY GAMES</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='games_wrapper'>
                    <div className='container'>
                        <div className='row'>
                            <h4>Total ({games.length})</h4>
                            {!!games.length && games.map((game, i) => (
                                <div className='col-12 col-md-6 col-lg-4' key={i}>
                                    <div className='game_box'>
                                        <div className='game_top_container'>
                                            <div className="game_top_link">
                                                {
                                                    categoryName !== 'Android' ?
                                                    <a href={game?.path}>
                                                        <button>Let's Play</button>
                                                    </a> : <button onClick={() => handleDownload(game?.path)}>Download</button>
                                                }
                                            </div>
                                            <img src={game?.image} alt='game' />
                                        </div>
                                        <div className='game_detail'>
                                            <div className='game_title_box'>
                                                <div className='game_title'>{game?.name}</div>
                                                <div>
                                                    <img src='/images/flag_circle.png' alt='flag circle' />
                                                </div>
                                            </div>
                                            <div className='game_description'>{game?.description}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="brand_list brand_list_footer">
                    <div className="container">
                        <div className="row align-items-center">
                            {/* <div className='col-12 col-lg-4 d-none d-lg-block'>
                                <p>Trusted By Brands Worldwide:</p>
                            </div> */}
                            <div className="col-12 col-lg-12">
                                <Slider {...topTenSettings}>
                                    {footerImages.map((image, index) => (
                                        <img key={`FooterSlide ${index + 1}`} src={image} alt={`Slide ${index + 1}`} />
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer_list">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer_container">
                                    Copyright © 2024 UV Gaming.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default AllGames