const gameConfig = [
  {
    category_name: "Sports",
    games: [
      {
        name: "3D Pin Pull",
        path: "/games/Sports Games/3D Pin Pull/Gamefiles/",
        image:
          "/games/Sports Games/3D Pin Pull/Images/3D_Pin_Pull_Banner_1830x855.jpg",
        description: "",
      },
      {
        name: "8 Ball Pro",
        path: "/games/Sports Games/8 Ball Pro/Gamefiles/",
        image:
          "/games/Sports Games/8 Ball Pro/Images/8_Ball_Pro_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Baseballclassic",
      //   path: "/games/Sports Games/BaseballClassic/Gamefiles/",
      //   image:
      //     "/games/Sports Games/BaseballClassic/Images/BaseballClassic_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "BasketballPractice",
      //   path: "/games/Sports Games/BasketballPractice/Gamefiles/",
      //   image:
      //     "/games/Sports Games/BasketballPractice/Images/BasketballPractice_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Black Jack",
      //   path: "/games/Sports Games/BlackJack/Gamefiles/",
      //   image:
      //     "/games/Sports Games/BlackJack/Images/BlackJack_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "CasinoCardsMemory",
      //   path: "/games/Sports Games/CasinoCardsMemory/Gamefiles/",
      //   image:
      //     "/games/Sports Games/CasinoCardsMemory/Images/CasinoCardsMemory_Banner_Large_1024x500 (2).png",
      //   description: "",
      // },
      // {
      //   name: "DD21Blitz",
      //   path: "/games/Sports Games/DD21Blitz/Gamefiles/",
      //   image:
      //     "/games/Sports Games/DD21Blitz/Images/DD21Blitz_Banner_Large_1024x500 (2).png",
      //   description: "",
      // },
      // {
      //   name: "Foos Ball",
      //   path: "/games/Sports Games/FoosBall/Gamefiles/",
      //   image:
      //     "/games/Sports Games/FoosBall/Images/FoosBall_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Foot golf Evolution",
      //   path: "/games/Sports Games/Foot golf Evolution/Gamefiles/",
      //   image:
      //     "/games/Sports Games/FootgolfEvolution/Images/FootgolfEvolution_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "GoalKeeper",
        path: "/games/Sports Games/GoalKeeper/Gamefiles/",
        image:
          "/games/Sports Games/GoalKeeper/Images/GoalKeeper_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "GoBowling",
        path: "/games/Sports Games/GoBowling/Gamefiles/",
        image:
          "/games/Sports Games/GoBowling/Images/GoBowling_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "GoBowling2",
        path: "/games/Sports Games/GoBowling2/Gamefiles/",
        image:
          "/games/Sports Games/GoBowling2/Images/GoBowling2_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Head Ball",
        path: "/games/Sports Games/HeadBall/Gamefiles/",
        image:
          "/games/Sports Games/HeadBall/Images/HeadBall_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "HighOrLow",
      //   path: "/games/Sports Games/HighOrLow/Gamefiles/",
      //   image:
      //     "/games/Sports Games/HighOrLow/Images/HighOrLow_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Karate",
        path: "/games/Sports Games/Karate/Gamefiles/",
        image:
          "/games/Sports Games/Karate/Images/Karate_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Karting",
      //   path: "/games/Sports Games/Karting/Gamefiles/",
      //   image:
      //     "/games/Sports Games/Karting/Images/Karting_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Kick The Soccer Ball",
        path: "/games/Sports Games/Kick The Soccer Ball/Gamefiles/",
        image:
          "/games/Sports Games/Kick The Soccer Ball/Images/Kick_The_Soccer_Ball_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Ludo",
        path: "/games/Sports Games/Ludo/Gamefiles/",
        image: "/games/Sports Games/Ludo/Images/Ludo_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Nave X Racer",
        path: "/games/Sports Games/Nave X Racer/Gamefiles/",
        image:
          "/games/Sports Games/Nave X Racer/Images/Nave_X_Racer_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Ping Pong",
        path: "/games/Sports Games/Ping Pong/Gamefiles/",
        image:
          "/games/Sports Games/Ping Pong/Images/Ping_Pong_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Roads With Cars",
        path: "/games/Sports Games/Roads With Cars/Gamefiles/",
        image:
          "/games/Sports Games/Roads With Cars/Images/Roads_With_Cars_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Skateboard Challenge",
        path: "/games/Sports Games/Skateboard Challenge/Gamesfiles/",
        image:
          "/games/Sports Games/Skateboard Challenge/Images/Skateboard_Challenge_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Skeeball",
        path: "/games/Sports Games/Skeeball/Gamefiles/",
        image:
          "/games/Sports Games/Skeeball/Images/Skeeball_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Snake & Ladders",
      //   path: "/games/Sports Games/Snake&Ladders/Gamefiles/",
      //   image:
      //     "/games/Sports Games/Snake&Ladders/Images/Snake&Ladders_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Snooker",
      //   path: "/games/Sports Games/Snooker/Gamefiles/",
      //   image:
      //     "/games/Sports Games/Snooker/Images/Snooker_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "SuperCricket",
      //   path: "/games/Sports Games/SuperCricket/Gamefiles/",
      //   image:
      //     "/games/Sports Games/SuperCricket/Images/SuperCricket_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Super Pon Goal",
        path: "/games/Sports Games/SuperPonGoal/Gamefiles/",
        image:
          "/games/Sports Games/SuperPonGoal/Images/SuperPonGoal_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "VolleyBall",
        path: "/games/Sports Games/VolleyBall/Gamefiles/",
        image:
          "/games/Sports Games/VolleyBall/Images/VolleyBall_Banner_Large_1024x500.png",
        description: "",
      },
    ],
  },
  {
    category_name: "Racing",
    games: [
      {
        name: "Barrier",
        path: "/games/Racing Games/Barrier/Gamefiles/",
        image:
          "/games/Racing Games/Barrier/Images/Barrier_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "BoatRush",
      //   path: "/games/Racing Games/BoatRush/Gamefiles/",
      //   image:
      //     "/games/Racing Games/BoatRush/Images/BoatRush_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Car Physics BTR80",
        path: "/games/Racing Games/CarPhysicsBTR80/Gamefiles/",
        image:
          "/games/Racing Games/CarPhysicsBTR80/Images/CarPhysicsBTR80_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "MaseratiGranturismo",
      //   path: "/games/Racing Games/MaseratiGranturismo/Gamefiles/",
      //   image:
      //     "/games/Racing Games/MaseratiGranturismo/Images/MaseratiGranturismo_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Police And Thief",
        path: "/games/Racing Games/PoliceAndThief/Gamefiles/",
        image:
          "/games/Racing Games/PoliceAndThief/Images/PoliceAndThief_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Race Car Transmission",
      //   path: "/games/Racing Games/RaceCarTransmission/Gamefiles/",
      //   image:
      //     "/games/Racing Games/RaceCarTransmission/Images/RaceCarTransmission_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Speed Racer",
        path: "/games/Racing Games/SpeedRacer/Gamefiles/",
        image:
          "/games/Racing Games/SpeedRacer/Images/SpeedRacer_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Traffic Racer 2",
        path: "/games/Racing Games/TrafficRacer2/Gamefiles/",
        image:
          "/games/Racing Games/TrafficRacer2/Images/TrafficRacer2_Banner_Large_1024x500.png",
        description: "",
      },
    ],
  },
  {
    category_name: "Adventure",
    games: [
      // {
      //   name: "Autoofroad",
      //   path: "/games/Adventure Games/Autoofroad/Gamefiles/",
      //   image:
      //     "/games/Adventure Games/Autoofroad/Images/Autoofroad_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Barbarian vs Mummy",
        path: "/games/Adventure Games/Barbarian-vs-Mummy/Gamefiles/",
        image:
          "/games/Adventure Games/Barbarian-vs-Mummy/Images/Barbarian_vs_Mummy_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Battboy Adventure",
        path: "/games/Adventure Games/Battboy Adventure/Gamefiles/",
        image:
          "/games/Adventure Games/Battboy Adventure/Images/Battboy_Adventure_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Crazyrunner",
        path: "/games/Adventure Games/CrazyRunner/Gamefiles/",
        image:
          "/games/Adventure Games/CrazyRunner/Images/Crazyrunner_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Dead Land Adventure 2",
        path: "/games/Adventure Games/DeadLandAdventure2/Gamefiles/",
        image:
          "/games/Adventure Games/DeadLandAdventure2/Images/DeadLandAdventure2_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Jungles Adventures",
        path: "/games/Adventure Games/Jungles Adventures/Gamefiles/",
        image:
          "/games/Adventure Games/Jungles Adventures/Images/Jungles_Adventures_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Motocross Xtreme",
      //   path: "/games/Adventure Games/MotocrossXtreme/Gamefiles/",
      //   image:
      //     "/games/Adventure Games/MotocrossXtreme/Images/MotocrossXtreme_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Motor cyclists",
        path: "/games/Adventure Games/Motorcyclists/Gamefiles/",
        image:
          "/games/Adventure Games/Motorcyclists/Images/Motorcyclists_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Ranger vs Zombies",
        path: "/games/Adventure Games/RangervsZombies/Gamefiles/",
        image:
          "/games/Adventure Games/RangervsZombies/Images/RangervsZombies_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Save The Pilot",
        path: "/games/Adventure Games/SaveThePilot/Gamefiles/",
        image:
          "/games/Adventure Games/SaveThePilot/Images/SaveThePilot_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Space purge",
      //   path: "/games/Adventure Games/Spacepurge/Gamefiles/",
      //   image:
      //     "/games/Adventure Games/SpacePurge/Images/Spacepurge_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Space Rocket",
        path: "/games/Adventure Games/SpaceRocket/Gamefiles/",
        image:
          "/games/Adventure Games/SpaceRocket/Images/SpaceRocket_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "SVS",
        path: "/games/Adventure Games/SVS/Gamefiles/",
        image:
          "/games/Adventure Games/SVS/Images/SVS_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Tank Defender",
      //   path: "/games/Adventure Game/TankDefender/Gamefiles/",
      //   image:
      //     "/games/Adventure Games/TankDefender/Images/TankDefender_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Tank Wars",
      //   path: "/games/Adventure Game/TankWars/Gamefiles/",
      //   image:
      //     "/games/Adventure Games/TankWars/Images/TankWars_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "The Last Battle",
      //   path: "/games/Adventure Game/TheLastBattle/Gamefiles/",
      //   image:
      //     "/games/Adventure Games/TheLastBattle/Images/TheLastBattle_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Vikings Vs Skeletons",
      //   path: "/games/Adventure Game/VikingsVsSkeletons/Gamefiles/",
      //   image:
      //     "/games/Adventure Games/VikingsVsSkeletons/Images/VikingsVsSkeletons_Banner_Large_1024x500.png",
      //   description: "",
      // },
    ],
  },
  {
    category_name: "Funny",
    games: [
      {
        name: "Angry Cat Shot",
        path: "/games/Fun games/AngryCatShot/Gamefiles/",
        image:
          "/games/Fun games/AngryCatShot/Images/AngryCatShot_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Animal Fun",
        path: "/games/Fun games/AnimalFun/Gamefiles/",
        image:
          "/games/Fun games/AnimalFun/Images/AnimalFun_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Animal Touch",
      //   path: "/games/Fun games/AnimalTouch/Gamefiles/",
      //   image:
      //     "/games/Fun games/AnimalTouch/Images/AnimalTouch_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Ball And Target",
      //   path: "/games/Fun games/BallAndTarget/Gamefiles/",
      //   image:
      //     "/games/Fun games/BallAndTarget/Images/BallAndTarget_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Ballio",
        path: "/games/Fun games/Ballio/Gamefiles/",
        image:
          "/games/Fun games/Ballio/Images/Ballio_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Barn Dash",
        path: "/games/Fun games/Barn Dash/Gamefiles/",
        image:
          "/games/Fun games/Barn Dash/Images/Barn_Dash_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Countries Hangman",
        path: "/games/Fun games/Countries Hangman/Gamefiles/",
        image:
          "/games/Fun games/Countries Hangman/Images/Countries_Hangman_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Count the Cards",
      //   path: "/games/Fun games/CounttheCards/Gamefiles/",
      //   image:
      //     "/games/Fun games/CounttheCards/Images/CounttheCards_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Creepy Day",
        path: "/games/Fun games/Creepy Day/Gamefiles/",
        image:
          "/games/Fun games/Creepy Day/Images/Creepy_Day_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Halloween Hangman",
        path: "/games/Fun games/Halloween Hangman/Gamefiles/",
        image:
          "/games/Fun games/Halloween Hangman/Images/Halloween_Hangman_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Halloween Memory Game",
        path: "/games/Fun games/Halloween Memory Game/Gamefiles/",
        image:
          "/games/Fun games/Halloween Memory Game/Images/Halloween_Memory_Game_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Halloween Word Search",
        path: "/games/Fun games/Halloween Word Search/Gamefiles/",
        image:
          "/games/Fun games/Halloween Word Search/Images/Halloween_Word_Search_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Halloween Bubble Shooter",
        path: "/games/Fun games/HalloweenBubbleShooter/Gamefiles/",
        image:
          "/games/Fun games/HalloweenBubbleShooter/Images/HalloweenBubbleShooter_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Halloween Memory",
        path: "/games/Fun games/HalloweenMemory/Gamefiles/",
        image:
          "/games/Fun games/HalloweenMemory/Images/HalloweenMemory_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Handless Millionaire",
        path: "/games/Fun games/HandlessMillionaire/Gamefiles/",
        image:
          "/games/Fun games/HandlessMillionaire/Images/HandlessMillionaire_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Happy Halloween",
        path: "/games/Fun games/HappyHalloween/Gamefiles/",
        image:
          "/games/Fun games/HappyHalloween/Images/HappyHalloween_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "HororHalloween",
        path: "/games/Fun games/HororHalloween/Gamefiles/",
        image:
          "/games/Fun games/HororHalloween/Images/HororHalloween_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Hot Jewels",
        path: "/games/Fun games/HotJewels/Gamefiles/",
        image:
          "/games/Fun games/HotJewels/Images/HotJewels_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Hungry Chameleon",
        path: "/games/Fun games/HungryChameleon/Gamefiles/",
        image:
          "/games/Fun games/HungryChameleon/Images/HungryChameleon_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Hungry Number",
      //   path: "/games/Fun games/HungryNumber/Gamefiles/",
      //   image:
      //     "/games/Fun games/HungryNumber/Images/HungryNumber_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Jewelry Competition",
        path: "/games/Fun games/JewelryCompetition/Gamefiles/",
        image:
          "/games/Fun games/JewelryCompetition/Images/JewelryCompetition_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Katana Fruits",
        path: "/games/Fun games/Katana Fruits/Gamefiles/",
        image:
          "/games/Fun games/Katana Fruits/Images/Katana_Fruits_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Knot Logic Game",
        path: "/games/Fun games/Knot Logic Game/Gamefiles/",
        image:
          "/games/Fun games/Knot Logic Game/Images/Knot_Logic_Game_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Line bright",
        path: "/games/Fun games/Linebright/Gamefiles/",
        image:
          "/games/Fun games/Linebright/Images/Linebright_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Mad Scientist",
        path: "/games/Fun games/MadScientist/Gamefiles/",
        image:
          "/games/Fun games/MadScientist/Images/MadScientist_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "MadShark",
        path: "/games/Fun games/MadShark/Gamefiles/",
        image:
          "/games/Fun games/MadShark/Images/MadShark_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Neon",
      //   path: "/games/Fun games/Neon/Gamefiles/",
      //   image: "/games/Fun games/Neon/Images/Neon_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Neon Invaders",
        path: "/games/Fun games/Neon Invaders/Gamefiles/",
        image:
          "/games/Fun games/Neon Invaders/Images/Neon_Invaders_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Neon Rocket",
        path: "/games/Fun games/Neon Rocket/Gamefiles/",
        image:
          "/games/Fun games/Neon Rocket/Images/Neon_Rocket_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Neon Shot",
        path: "/games/Fun games/Neon Shot/Gamefiles/",
        image:
          "/games/Fun games/Neon Shot/Images/Neon_Shot_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Neon Snake",
        path: "/games/Fun games/Neon Snake/Gamefiles/",
        image:
          "/games/Fun games/Neon Snake/Images/Neon_Snake_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Plumber",
        path: "/games/Fun games/Plumber/Gamefiles/",
        image:
          "/games/Fun games/Plumber/Images/Plumber_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Rolling Ball",
        path: "/games/Fun games/Rolling Ball/Gamefiles/",
        image:
          "/games/Fun games/Rolling Ball/Images/Rolling_Ball_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Sling Tomb",
        path: "/games/Fun games/SlingTomb/Gamefiles/",
        image:
          "/games/Fun games/SlingTomb/Images/SlingTomb_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Traffic Command",
        path: "/games/Fun games/TrafficCommand/Gamefiles/",
        image:
          "/games/Fun games/TrafficCommand/Images/TrafficCommand_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "traveler",
        path: "/games/Fun games/traveler/Gamefiles/",
        image:
          "/games/Fun games/traveler/Images/traveler_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Zombie Uprising",
      //   path: "/games/Fun games/ZombieUprising/Gamefiles/",
      //   image:
      //     "/games/Fun games/Zombie Uprising/Images/ZombieUprising_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Zombie Buster",
      //   path: "/games/Fun games/ZombieBuster/Gamefiles/",
      //   image:
      //     "/games/Fun games/ZombieBuster/Images/ZombieBuster_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Zombie Dentist",
      //   path: "/games/Fun games/ZombieDentist/Gamefiles/",
      //   image:
      //     "/games/Fun games/ZombieDentist/Images/ZombieDentist_Banner_Large_1024x500.png",
      //   description: "",
      // },
    ],
  },
  {
    category_name: "Kids",
    games: [
      {
        name: "Balloon Paradise",
        path: "/games/Kids Games/BalloonParadise/Gamefiles/",
        image:
          "/games/Kids Games/BalloonParadise/Images/BalloonParadise_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "BrickOut",
        path: "/games/Kids Games/BrickOut/Gamefiles/",
        image:
          "/games/Kids Games/BrickOut/Images/BrickOut_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Candy Truck",
        path: "/games/Kids Games/CandyTruck/Gamefiles/",
        image:
          "/games/Kids Games/CandyTruck/Images/CandyTruck_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Cartoon Coloring Book",
        path: "/games/Kids Games/Cartoon Coloring Book/Gamefiles/",
        image:
          "/games/Kids Games/Cartoon Coloring Book/Images/Cartoon_Coloring_Book_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Cartoon Coloring For Kids Animals",
        path: "/games/Kids Games/Cartoon Coloring for Kids Animals/Gamefiles/",
        image:
          "/games/Kids Games/Cartoon Coloring for Kids Animals/Images/Cartoon_Coloring_For_Kids_Animals_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Cartoon Puzzle",
        path: "/games/Kids Games/Cartoon Puzzle/Gamefiles/",
        image:
          "/games/Kids Games/Cartoon Puzzle/Images/Cartoon_Puzzle_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Christmas Math",
        path: "/games/Kids Games/Christmas_Math/Gamefiles/",
        image:
          "/games/Kids Games/Christmas_Math/Images/Christmas_Math_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Christmas Math",
        path: "/games/Kids Games/ChristmasMath/Gamefiles/",
        image:
          "/games/Kids Games/ChristmasMath/Images/ChristmasMath_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Coloring Book Airplane",
      //   path: "/games/Kids Games/ColoringBookAirplane/Gamefiles/",
      //   image:
      //     "/games/Kids Games/ColoringBookAirplane/Images/ColoringBookAirplane_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Crosswordforkids",
        path: "/games/Kids Games/Crosswordforkids/Gamefiles/",
        image:
          "/games/Kids Games/Crosswordforkids/Images/Crosswordforkids_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Draw 123",
        path: "/games/Kids Games/Draw 123/Gamefiles/",
        image: "/games/Kids Games/Draw 123/Images/Draw_123_Banner_1830x855.jpg",
        description: "",
      },
      // {
      //   name: "Dribble Kings Football",
      //   path: "/games/Kids Games/DribbleKingsFootball/Gamefiles/",
      //   image:
      //     "/games/Kids Games/DribbleKingsFootball/Images/DribbleKingsFootball_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Duck Hunter",
        path: "/games/Kids Games/DuckHunter/Gamefiles/",
        image:
          "/games/Kids Games/DuckHunter/Images/DuckHunter_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "ET",
        path: "/games/Kids Games/ET/Gamefiles/",
        image: "/games/Kids Games/ET/Images/ET_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Fish Coloring Book",
        path: "/games/Kids Games/Fish Coloring Book/Gamefiles/",
        image:
          "/games/Kids Games/Fish Coloring Book/Images/Fish_Coloring_Book_Banner_1830x855.jpg",
        description: "",
      },
      {
        name: "FruitSnak",
        path: "/games/Kids Games/FruitSnake/Gamefiles/",
        image:
          "/games/Kids Games/FruitSnake/Images/FruitSnake_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Grammar Gamble",
        path: "/games/Kids Games/GrammarGamble/Gamefiles/",
        image:
          "/games/Kids Games/GrammarGamble/Images/GrammarGamble_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Guess Number",
        path: "/games/Kids Games/Guess Number/Gamefiles/",
        image:
          "/games/Kids Games/Guess Number/Images/Guess_Number_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Just Draw",
        path: "/games/Kids Games/Just Draw/Gamefiles/",
        image:
          "/games/Kids Games/Just Draw/Images/Just_Draw_Banner_1830x855.jpg",
        description: "",
      },
      {
        name: "KidsTrueColor",
        path: "/games/Kids Games/KidsTrueColor/Gamefiles/",
        image:
          "/games/Kids Games/KidsTrueColor/Images/KidsTrueColor_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Little Strawberry",
        path: "/games/Kids Games/LittleStrawberry/Gamefiles/",
        image:
          "/games/Kids Games/LittleStrawberry/Images/LittleStrawberry_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Mad Fish",
        path: "/games/Kids Games/Mad Fish/Gamefiles/",
        image:
          "/games/Kids Games/Mad Fish/Images/Mad_Fish_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Make Your Little Boy",
      //   path: "/games/Kids Games/MakeYourLittleBoy/Gamefiles/",
      //   image:
      //     "/games/Kids Games/MakeYourLittleBoy/Images/MakeYourLittleBoy_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Math Game For Kids",
        path: "/games/Kids Games/MathGameForKids/Gamefiles/",
        image:
          "/games/Kids Games/MathGameForKids/Images/MathGameForKids_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Maze And Tourist",
      //   path: "/games/Kids Games/MazeAndTourist/Gamefiles/",
      //   image:
      //     "/games/Kids Games/MazeAndTourist/Images/MazeAndTourist_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Monster Rush",
        path: "/games/Kids Games/Monster Rush/Gamefiles/",
        image:
          "/games/Kids Games/Monster Rush/Images/Monster_Rush_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Monster Truck",
      //   path: "/games/Kids Games/MonsterTruck/Gamefiles/",
      //   image:
      //     "/games/Kids Games/MonsterTruck/Images/MonsterTruck_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Mummy Candies",
        path: "/games/Kids Games/MummyCandies/Gamefiles/",
        image:
          "/games/Kids Games/MummyCandies/Images/MummyCandies_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Mysterious Candies",
        path: "/games/Kids Games/MysteriousCandies/Gamefiles/",
        image:
          "/games/Kids Games/MysteriousCandies/Images/MysteriousCandies_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Paint Gun",
        path: "/games/Kids Games/Paint Gun/Gamefiles/",
        image:
          "/games/Kids Games/Paint Gun/Images/Paint_Gun_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Ring the answer",
        path: "/games/Kids Games/Ringtheanswer/Gamefiles/",
        image:
          "/games/Kids Games/Ringtheanswer/Images/Ringtheanswer_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Running Pumpkin",
        path: "/games/Kids Games/Running Pumpkin/Gamefiles/",
        image:
          "/games/Kids Games/Running Pumpkin/Images/Running Pumpking_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Schitalochka",
        path: "/games/Kids Games/Schitalochka/Gamefiles/",
        image:
          "/games/Kids Games/Schitalochka/Images/Schitalochka_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Smiles",
        path: "/games/Kids Games/Smiles/Gamefiles/",
        image:
          "/games/Kids Games/Smiles/Images/Smiles_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "SpeedyFish",
      //   path: "/games/Kids Games/SpeedyFish/Gamefiles/",
      //   image:
      //     "/games/Kids Games/SpeedyFish/Images/SpeedyFish_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Spinning Colors",
        path: "/games/Kids Games/SpinningColors/Gamefiles/",
        image:
          "/games/Kids Games/SpinningColors/Images/SpinningColors_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Street Skater",
        path: "/games/Kids Games/StreetSkater/Gamefiles/",
        image:
          "/games/Kids Games/StreetSkater/Images/StreetSkater_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Tappy Dumount",
        path: "/games/Kids Games/Tappy Dumount/Gamefiles/",
        image:
          "/games/Kids Games/Tappy Dumount/Images/Tappy_Dumount_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Tom Runner",
        path: "/games/Kids Games/TomRunner/Gamefiles/",
        image:
          "/games/Kids Games/TomRunner/Images/TomRunner_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Turtle Jump",
        path: "/games/Kids Games/Turtle Jump/Gamefiles/",
        image:
          "/games/Kids Games/Turtle Jump/Images/Turtle_Jump_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Young Santa 2",
        path: "/games/Kids Games/Young Santa 2/Gamefiles/",
        image:
          "/games/Kids Games/Young Santa 2/Images/Young_Santa_2_Banner_1830x855.jpg",
        description: "",
      },
    ],
  },
  {
    category_name: "Action",
    games: [
      {
        name: "Air Strike",
        path: "/games/Action Games/Air Strike/Gamefiles/",
        image:
          "/games/Action Games/Air Strike/Images/Air_Strike_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "AirWarfare",
        path: "/games/Action Games/AirWarfare/Gamefiles/",
        image:
          "/games/Action Games/AirWarfare/Images/AirWarfare_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Brave Warriors",
        path: "/games/Action Games/Brave Warriors/Gamefiles/",
        image:
          "/games/Action Games/Brave Warriors/Images/Brave_Warriors_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Crazy Gravity",
        path: "/games/Action Games/Crazy Gravity/Gamefiles/",
        image:
          "/games/Action Games/Crazy Gravity/Images/Crazy_Gravity_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "CrazyDriver",
      //   path: "/games/Action Games/CrazyDriver/Gamefiles/",
      //   image:
      //     "/games/Action Games/CrazyDriver/Images/CrazyDriver_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "CrazyRoad",
      //   path: "/games/Action Games/CrazyRoad/Gamefiles/",
      //   image:
      //     "/games/Action Games/CrazyRoad/Images/CrazyRoad_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Dark Ninja",
        path: "/games/Action Games/Dark Ninja/Gamefiles/",
        image:
          "/games/Action Games/Dark Ninja/Images/Dark_Ninja_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Escape From Ghost And Collect Diamonds",
        path: "/games/Action Games/Escape From Ghost And Collect Diamonds/Gamefiles/",
        image:
          "/games/Action Games/Escape From Ghost And Collect Diamonds/Images/Escape_From_Ghost_And_Collect_Diamonds_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Extreme Fighters",
        path: "/games/Action Games/Extreme Fighters/Gamefiles/",
        image:
          "/games/Action Games/Extreme Fighters/Images/Extreme_Fighters_Banner_1830x855.jpg",
        description: "",
      },
      // {
      //   name: "Flat Crossbar Challenge",
      //   path: "/games/Action Games/FlatCrossbarChallenge/Gamefiles/",
      //   image:
      //     "/games/Action Games/FlatCrossbarChallenge/Images/FlatCrossbarChallenge_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Galactic War",
      //   path: "/games/Action Games/Galactic War/Gamefiles/",
      //   image:
      //     "/games/Action Games/Galactic War/Images/Galactic_War_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Horik Viking",
        path: "/games/Action Games/Horik Viking/Gamefiles/",
        image:
          "/games/Action Games/Horik Viking/Images/Horik_Viking_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Jojo Run",
        path: "/games/Action Games/Jojo Run/Gamefiles/",
        image:
          "/games/Action Games/Jojo Run/Images/Jojo_Run_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Kingdom Defence",
        path: "/games/Action Games/Kingdom Defence/Gamefiles/",
        image:
          "/games/Action Games/Kingdom Defence/Images/Kingdom_Defence_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "LightRays",
      //   path: "/games/Action Games/LightRays/Gamefiles/",
      //   image:
      //     "/games/Action Games/LightRays/Images/LightRays_Banner_Large_1024x500.png",
      //   description: "",
      // },
      // {
      //   name: "Mafia Agent",
      //   path: "/games/Action Games/MafiaAgent/Gamefiles/",
      //   image:
      //     "/games/Action Games/MafiaAgent/Images/MafiaAgent_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Ninja Boy",
        path: "/games/Action Games/Ninja Boy/Gamefiles/",
        image:
          "/games/Action Games/Ninja Boy/Images/Ninja_Boy_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "NinjaRun",
        path: "/games/Action Games/NinjaRun/Gamefiles/",
        image:
          "/games/Action Games/NinjaRun/Images/NinjaRun_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Sahara Invasion",
        path: "/games/Action Games/Sahara Invasion/Gamefiles/",
        image:
          "/games/Action Games/Sahara Invasion/Images/Sahara_Invasion_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Shadow Fighter",
        path: "/games/Action Games/ShadowFighter/Gamefiles/",
        image:
          "/games/Action Games/ShadowFighter/Images/ShadowFighter_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Steam Trucker",
        path: "/games/Action Games/SteamTrucker/Gamefiles/",
        image:
          "/games/Action Games/SteamTrucker/Images/SteamTrucker_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Super Archer",
        path: "/games/Action Games/Super Archer/Gamefiles/",
        image:
          "/games/Action Games/Super Archer/Images/Super_Archer_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Swat Vs Zombies",
        path: "/games/Action Games/SwatVsZombies/Gamefiles/",
        image:
          "/games/Action Games/SwatVsZombies/Images/SwatVsZombies_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "The Rescue Rocket",
        path: "/games/Action Games/TheRescueRocket/Gamefiles/",
        image:
          "/games/Action Games/TheRescueRocket/Images/TheRescueRocket_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Transformers",
        path: "/games/Action Games/Transformers/Gamefiles/",
        image:
          "/games/Action Games/Transformers/Images/Transformers_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "VikingEscape",
        path: "/games/Action Games/VikingEscape/Gamefiles/",
        image:
          "/games/Action Games/VikingEscape/Images/VikingEscape_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "WarriorsVsEvilSpirits",
        path: "/games/Action Games/WarriorsVsEvilSpirits/Gamefiles/",
        image:
          "/games/Action Games/WarriorsVsEvilSpirits/Images/WarriorsVsEvilSpirits_Banner_Large_1024x500.png",
        description: "",
      },
    ],
  },
  {
    category_name: "Puzzle",
    games: [
      {
        name: "Block Breaker",
        path: "/games/Puzzle Games/Block Breaker/Gamefiles/",
        image:
          "/games/Puzzle Games/Block Breaker/Images/Block_Breaker_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Boys Puzzle Game",
        path: "/games/Puzzle Games/Boys Puzzle Game/Gamefiles/",
        image:
          "/games/Puzzle Games/Boys Puzzle Game/Images/Boys_Puzzle_Game_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Brain Test",
        path: "/games/Puzzle Games/Brain Test/Gamefiles/",
        image:
          "/games/Puzzle Games/Brain Test/Images/Brain_Test_Banner_1830x855.jpg",
        description: "",
      },
      {
        name: "Calcudoku",
        path: "/games/Puzzle Games/Calcudoku/Gamefiles/",
        image:
          "/games/Puzzle Games/Calcudoku/Images/Calcudoku_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Circle Puzzle",
        path: "/games/Puzzle Games/Circle Puzzle/Gamefiles/",
        image:
          "/games/Puzzle Games/Circle Puzzle/Images/Circle_Puzzle_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Connect A Way",
        path: "/games/Puzzle Games/Connect A Way/Gamefiles/",
        image:
          "/games/Puzzle Games/Connect A Way/Images/Connect_A_Way_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Connect The Dots",
        path: "/games/Puzzle Games/Connect The Dots/Gamefiles/",
        image:
          "/games/Puzzle Games/Connect The Dots/Images/Connect_The_Dots_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Find Differences Christmas",
        path: "/games/Puzzle Games/Find Differences Christmas/Gamefiles/",
        image:
          "/games/Puzzle Games/Find Differences Christmas/Images/Find_Differences_Christmas_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Find Seven Differences Animals",
        path: "/games/Puzzle Games/Find Seven Differences Animals/Gamefiles/",
        image:
          "/games/Puzzle Games/Find Seven Differences Animals/Images/Find_Seven_Differences_Animals_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Find Seven Differences Kids",
        path: "/games/Puzzle Games/Find Seven Differences Kids/Gamefiles/",
        image:
          "/games/Puzzle Games/Find Seven Differences Kids/Images/Find_Seven_Differences_Kids_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Find The Cat",
        path: "/games/Puzzle Games/Find The Cat/Gamefiles/",
        image:
          "/games/Puzzle Games/Find The Cat/Images/Find_The_Cat_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Find Seven Differences",
        path: "/games/Puzzle Games/findSevenDifferences/Gamefiles/",
        image:
          "/games/Puzzle Games/findSevenDifferences/Images/findSevenDifferences_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "FishWorldMatch3",
        path: "/games/Puzzle Games/FishWorldMatch3/Gamefiles/",
        image:
          "/games/Puzzle Games/FishWorldMatch3/Images/FishWorldMatch3_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Match Doodle",
        path: "/games/Puzzle Games/Match Doodle/Gamefiles/",
        image:
          "/games/Puzzle Games/Match Doodle/Images/Match_Doodle_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Match Missing Pieces",
        path: "/games/Puzzle Games/Match Missing Pieces/Gamefiles/",
        image:
          "/games/Puzzle Games/Match Missing Pieces/Images/Match_Missing_Pieces_Banner_1830x855.jpg",
        description: "",
      },
      {
        name: "Math Sign Game",
        path: "/games/Puzzle Games/Math Sign Game/Gamefiles/",
        image:
          "/games/Puzzle Games/Math Sign Game/Images/Math_Sign_Game_Banner_Large_1024x500.png",
        description: "",
      },
      // {
      //   name: "Memory Challenge",
      //   path: "/games/Puzzle Games/MemoryChallenge/Gamefiles/",
      //   image:
      //     "/games/Puzzle Games/MemoryChallenge/Images/MemoryChallenge_Banner_Large_1024x500.png",
      //   description: "",
      // },
      {
        name: "Memory Game",
        path: "/games/Puzzle Games/MemoryGame/Gamefiles/",
        image:
          "/games/Puzzle Games/MemoryGame/Images/MemoryGame_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Mini Games Challenge",
        path: "/games/Puzzle Games/Mini Games Challenge/Gamefiles/",
        image:
          "/games/Puzzle Games/Mini Games Challenge/Images/Mini_Games_Challenge_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Pin Cracker",
        path: "/games/Puzzle Games/Pin Cracker/Gamefiles/",
        image:
          "/games/Puzzle Games/Pin Cracker/Images/Pin_Cracker_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Playing Cards Memory",
        path: "/games/Puzzle Games/Playing Cards Memory/Gamefiles/",
        image:
          "/games/Puzzle Games/Playing Cards Memory/Images/Playing_Cards_Memory_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Puzzle Farm",
        path: "/games/Puzzle Games/Puzzle Farm/Gamefiles/",
        image:
          "/games/Puzzle Games/Puzzle Farm/Images/Puzzle_Farm_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Puzzle Game Cartoon",
        path: "/games/Puzzle Games/Puzzle Game Cartoon/Gamefiles/",
        image:
          "/games/Puzzle Games/Puzzle Game Cartoon/Images/Cartoon_Puzzle_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Puzzle Rotate",
        path: "/games/Puzzle Games/Puzzle Rotate/Gamefiles/",
        image:
          "/games/Puzzle Games/Puzzle Rotate/Images/Puzzle_Rotate_Banner_1830x855.jpg",
        description: "",
      },
      {
        name: "Puzzle Swapping",
        path: "/games/Puzzle Games/Puzzle Swapping/Gamefiles/",
        image:
          "/games/Puzzle Games/Puzzle Swapping/Images/Puzzle_Swapping_Banner_1830x855.jpg",
        description: "",
      },
      {
        name: "Puzzle Game Boys",
        path: "/games/Puzzle Games/PuzzleGameBoys/Gamefiles/",
        image:
          "/games/Puzzle Games/PuzzleGameBoys/Images/PuzzleGameBoys_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Red Ball",
        path: "/games/Puzzle Games/Red Ball/Gamefiles/",
        image:
          "/games/Puzzle Games/Red Ball/Images/Red_Ball_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Red Block Puzzle",
        path: "/games/Puzzle Games/Red Block Puzzle/Gamefiles/",
        image:
          "/games/Puzzle Games/Red Block Puzzle/Images/Red_Block_Puzzle_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Seven Differences",
        path: "/games/Puzzle Games/Seven Differences/Gamefiles/",
        image:
          "/games/Puzzle Games/Seven Differences/Images/Seven_Differences_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Soroban",
        path: "/games/Puzzle Games/Soroban/Gamefiles/",
        image:
          "/games/Puzzle Games/Soroban/Images/Soroban_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Square",
        path: "/games/Puzzle Games/Square/Gamefiles/",
        image:
          "/games/Puzzle Games/Square/Images/Square_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Super Word Search",
        path: "/games/Puzzle Games/Super Word Search/Gamefiles/",
        image:
          "/games/Puzzle Games/Super Word Search/Images/Super_Word_Search_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Super cars Puzzle",
        path: "/games/Puzzle Games/SupercarsPuzzle/Gamefiles/",
        image:
          "/games/Puzzle Games/SupercarsPuzzle/Images/SupercarsPuzzle_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Super Color Lines",
        path: "/games/Puzzle Games/SuperColorLines/Gamefiles/",
        image:
          "/games/Puzzle Games/SuperColorLines/Images/SuperColorLines_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Winter Holidays",
        path: "/games/Puzzle Games/WinterHolidays/Gamefiles/",
        image:
          "/games/Puzzle Games/WinterHolidays/Images/WinterHolidays_Banner_Large_1024x500.png",
        description: "",
      },
      {
        name: "Word Search",
        path: "/games/Puzzle Games/Word Search/Gamefiles/",
        image:
          "/games/Puzzle Games/Word Search/Images/Word_Search_Banner_Large_1024x500.png",
        description: "",
      },
    ],
  },
  {
    category_name: "Android",
    games: [
      {
        name: "Puzzle Box",
        path: "/games/AndroidGames/PuzzleBox/Gamefiles/1666358652_PuzzleBox.apk",
        image:
          "/games/AndroidGames/PuzzleBox/Images/PuzzleBox_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "Shape Change",
        path: "/games/AndroidGames/ShapeChange/Gamefiles/1666358804_Shape_Change.apk",
        image:
          "/games/AndroidGames/ShapeChange/Images/Shape_Change_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "Shape Havoc",
        path: "/games/AndroidGames/ShapeHavoc/Gamefiles/1666359645_Shape_Havoc.apk",
        image:
          "/games/AndroidGames/ShapeHavoc/Images/Shape_Havoc_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "Shape Race",
        path: "/games/AndroidGames/ShapeRace/Gamefiles/1666359787_Shape_Race.apk",
        image:
          "/games/AndroidGames/ShapeRace/Images/Shape_Race_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "SnakeBlock3D",
        path: "/games/AndroidGames/SnakeBlock3D/Gamefiles/1666360760_SnakeandBlock.apk",
        image:
          "/games/AndroidGames/SnakeBlock3D/Images/Snakes_And_Block_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "Snake3D",
        path: "/games/AndroidGames/Snake3D/Gamefiles/1667227804_3D_Snake.apk",
        image:
          "/games/AndroidGames/Snake3D/Images/3D_Snake_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "3DBallInImage",
        path: "/games/AndroidGames/3DBallInImage/Gamefiles/1667228026_3DBallInMaze.apk",
        image:
          "/games/AndroidGames/3DBallInImage/Images/3DBallInMaze_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "DunkAlot",
        path: "/games/AndroidGames/DunkAlot/Gamefiles/1667228319_Basket_Ball_Dunk.apk",
        image:
          "/games/AndroidGames/DunkAlot/Images/Basket_Ball_Dunk_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "BottleTap",
        path: "/games/AndroidGames/BottleTap/Gamefiles/1667229339_Bottle_Tap.apk",
        image:
          "/games/AndroidGames/BottleTap/Images/Bottle_Tap_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "Branches",
        path: "/games/AndroidGames/Branches/Gamefiles/1667229458_Branches.apk",
        image:
          "/games/AndroidGames/Branches/Images/Branches_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "BubbleStack",
        path: "/games/AndroidGames/BubbleStack/Gamefiles/1667229743_BubbleStack.apk",
        image:
          "/games/AndroidGames/BubbleStack/Images/BubbleStack_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "BurningMan2",
        path: "/games/AndroidGames/BurningMan2/Gamefiles/1667230349_BurningMan2.apk",
        image:
          "/games/AndroidGames/BurningMan2/Images/Burning_Man_2_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "Bus&Subway",
        path: "/games/AndroidGames/Bus&Subway/Gamefiles/1667230636_BusSubway.apk",
        image:
          "/games/AndroidGames/Bus&Subway/Images/Bus_Subway_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "CheesyRun",
        path: "/games/AndroidGames/CheesyRun/Gamefiles/1667230773_CheesyRun.apk",
        image:
          "/games/AndroidGames/CheesyRun/Images/Cheesy_Run_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "ColorPath",
        path: "/games/AndroidGames/ColorPath/Gamefiles/1667230879_Color_Path.apk",
        image:
          "/games/AndroidGames/ColorPath/Images/Color_Path_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "CrashyRacing",
        path: "/games/AndroidGames/CrashyRacing/Gamefiles/1667231191_CrashyRacing.apk",
        image:
          "/games/AndroidGames/CrashyRacing/Images/Crashy_Racing_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "CuteAnimalsPuzzles",
        path: "/games/AndroidGames/CuteAnimalsPuzzles/Gamefiles/1667231301_Cute_Animal_Puzzles.apk",
        image:
          "/games/AndroidGames/CuteAnimalsPuzzles/Images/Cute_Animal_Puzzles_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "PrisonEscapePlan",
        path: "/games/AndroidGames/PrisonEscapePlan/Gamefiles/1667231450_EscapePlanAPk.apk",
        image:
          "/games/AndroidGames/PrisonEscapePlan/Images/PRISON_ESCAPE_PLAN_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "FattyNinja",
        path: "/games/AndroidGames/FattyNinja/Gamefiles/1667231576_Fatty_ninja.apk",
        image:
          "/games/AndroidGames/FattyNinja/Images/Fatty_Ninja_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "MergeBlockRising",
        path: "/games/AndroidGames/MergeBlockRising/Gamefiles/1667231780_MergeBlockRising.apk",
        image:
          "/games/AndroidGames/MergeBlockRising/Images/MergeBlockRising_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "MergePlants",
        path: "/games/AndroidGames/MergePlants/Gamefiles/1667231899_MergePlant.apk",
        image:
          "/games/AndroidGames/MergePlants/Images/MergePlant_Banner_665x374.jpg",
        description: "",
      },
      {
        name: "PipeOut",
        path: "/games/AndroidGames/PipeOut/Gamefiles/1667232016_plumber_Ultimate.apk",
        image:
          "/games/AndroidGames/PipeOut/Images/Plumber_Ultimate_Banner_665x374.jpg",
        description: "",
      },
    ],
  },
];

                       

export default gameConfig

