const top10Games = [
  {
    name: "3D Pin Pull",
    path: "/games/Sports Games/3D Pin Pull/Gamefiles/",
    image:
      "/games/Sports Games/3D Pin Pull/Images/3D_Pin_Pull_Banner_1830x855.jpg",
    description: "",
  },
  {
    name: "8 Ball Pro",
    path: "/games/Sports Games/8 Ball Pro/Gamefiles/",
    image:
      "/games/Sports Games/8 Ball Pro/Images/8_Ball_Pro_Banner_Large_1024x500.png",
    description: "",
  },
  {
    name: "Ludo",
    path: "/games/Sports Games/Ludo/Gamefiles/",
    image: "/games/Sports Games/Ludo/Images/Ludo_Banner_Large_1024x500.png",
    description: "",
  },
  {
    name: "Ping Pong",
    path: "/games/Sports Games/Ping Pong/Gamefiles/",
    image:
      "/games/Sports Games/Ping Pong/Images/Ping_Pong_Banner_Large_1024x500.png",
    description: "",
  },
  {
    name: "Barrier",
    path: "/games/Racing Games/Barrier/Gamefiles/",
    image:
      "/games/Racing Games/Barrier/Images/Barrier_Banner_Large_1024x500.png",
    description: "",
  },
  {
    name: "GoalKeeper",
    path: "/games/Sports Games/GoalKeeper/Gamefiles/",
    image:
      "/games/Sports Games/GoalKeeper/Images/GoalKeeper_Banner_Large_1024x500.png",
    description: "",
  },
  {
    name: "GoBowling",
    path: "/games/Sports Games/GoBowling/Gamefiles/",
    image:
      "/games/Sports Games/GoBowling/Images/GoBowling_Banner_Large_1024x500.png",
    description: "",
  },
  {
    name: "Head Ball",
    path: "/games/Sports Games/HeadBall/Gamefiles/",
    image:
      "/games/Sports Games/HeadBall/Images/HeadBall_Banner_Large_1024x500.png",
    description: "",
  },
  {
    name: "Speed Racer",
    path: "/games/Racing Games/SpeedRacer/Gamefiles/",
    image:
      "/games/Racing Games/SpeedRacer/Images/SpeedRacer_Banner_Large_1024x500.png",
    description: "",
  },
  {
    name: "SVS",
    path: "/games/Adventure Games/SVS/Gamefiles/",
    image:
      "/games/Adventure Games/SVS/Images/SVS_Banner_Large_1024x500.png",
    description: "",
  },
];

export { top10Games };
