import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import './css/style.css';
import './css/login1.css';
import { unsubApi } from '../services/user-api';
import {service,serviceId,Loader} from "../utils/constant";

const Unsub = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState({message: "",error: false});
    const [flow, setFlow] = useState(false);
    
      const validatePhoneNumber = (phoneNumber) => {
        // Define a regular expression for a typical 10-digit US phone number
        const phoneRegex = /^\d{10}$/;
      
        // Test the provided phone number against the regular expression
        return phoneRegex.test(phoneNumber);
      }
  

    const unsubHit = async() => {
        console.log(phone); 
        setLoading(true);
        await unsubApi({action:2,serviceId:serviceId,mode:'WAP'}).then((res) => {
            setMessage({message: "You are successfully unsubscribed from the servce.",error: false})
            setFlow(true); 
            localStorage.clear(); 
        }).catch((err) => {
            console.log(err)
            setMessage({message: err.message,error: true})
        }).finally(() => setLoading(false))
       
      };

 
    return (
        
            <div className='home'>
                <div className="maine-div">
                    <div className="container-fluid box-wrapper login"> 
                        <div className='pt-5'>                                                  
                            <div className="login-thumbnail">
                                 <img src="/images/uv_games_logo.png" alt="UVGames" />                            
                            </div>                    
                        </div>

                        <div className="login-header pb-4">
                            <div className="box-wrapper mx-auto text-center position-relative"> 
                                <span className="modal-level-title game-title">Welcome to UVGames</span>
                            </div>
                        </div>

                        <div className="login-info">
                            {service==="mtnz"?
                                <div className="login-thumbnail3">                            
                                <img src="/images/login_img_new.png" alt="UVGames" />
                                </div>  
                            :
                                <div className="login-thumbnail2">
                                    <img src="/images/login_img.png" alt="UVGames" />
                                </div>
                            }
                            {flow?<>
                                <h3 className="login-title mb-3 mt-3 text-center">&nbsp;</h3>
                                {
                                    message.message && <div className="mb-3">
                                    <p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p>
                                    </div>
                                }
                            </>
                            :<>
                                <h3 className="login-title mb-3 mt-3 text-center">To unsubscribe from the service, Click on unsub.</h3>
                                {
                                    message.message && <div className="mb-3">
                                    <p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p>
                                    </div>
                                }
                                {loading ? <Loader />:<>
                                <button className="btn btn-primary btn-pm-custom" type="button" onClick={unsubHit} disabled={ButtonDisabled}>Unsub</button>
                                <div className="resend_otp">
                                        <NavLink to='/' className="send_otp_link" > &lt;&lt;&nbsp;Back</NavLink>
                                </div></>                            
                                }
                            </>
                        }
                        </div>
                        
                    </div>
                </div>
            </div>
       
    )
}

export default Unsub