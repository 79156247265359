import "./App.css";
import ProtectedRoute from "./utils/protectedRoute";
import OpenRoute from "./utils/openRoute";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const token = localStorage.getItem("token");
  return (
    <>
      <Router>
        {!token ? <OpenRoute /> : <ProtectedRoute />}
      </Router>
    </>
  );
}

export default App;
